import React, { Fragment } from "react";
import moment from "moment";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGCarouselComponent } from "../common/dgdgcarousel/DGDGCarouselComponent";
import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGFormCheckboxComponent } from "../common/dgdgcheckbox/DGDGFormCheckboxComponent";
import { DGDGFormCheckboxV2Component } from "../common/dgdgcheckbox/DGDGFormCheckboxV2Component";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

import { UsedReconditioningInventoryDesktopComponent } from "./UsedReconditioningInventoryDesktopComponent";
import { UsedReconditioningInventoryMobileComponent } from "./UsedReconditioningInventoryMobileComponent";
import { UsedReconditioningInventoryQueryComponent } from "./UsedReconditioningInventoryQueryComponent";

export class UsedReconditioningInventoryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            selectedStore: null,
            tableTitle: "",
            tableData: [],
            paintTable: false,
            undoDetail: null,
            actionName: null,
            stockNumber: "",
            serviceActiveCount: "",
            bodyActiveCount: "",
            detailActiveCount: "",
            reconActiveCount: "",
            photoActiveCount: "",

            wholesaleRow: null,
            showWholesaleConfirmation: false,

            cpoRow: null,
            showCPOConfirmation: false,

            publishRow: null,
            showPublishConfirmation: false
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        this.onWholesaleSelectChange = this.onWholesaleSelectChange.bind(this);
        this.onOkWholesaleChange = this.onOkWholesaleChange.bind(this);
        this.onCancelWholesaleClick = this.onCancelWholesaleClick.bind(this);

        this.onCPOSelectChange = this.onCPOSelectChange.bind(this);
        this.onOkCPOChange = this.onOkCPOChange.bind(this);
        this.onCancelCPOClick = this.onCancelCPOClick.bind(this);

        this.onSmogHoldChange = this.onSmogHoldChange.bind(this);
        this.onVinHoldChange = this.onVinHoldChange.bind(this);
        this.onWeightHoldChange = this.onWeightHoldChange.bind(this);
        this.onServiceHoldChange = this.onServiceHoldChange.bind(this);
        this.onServiceOutCarousalCallback = this.onServiceOutCarousalCallback.bind(this);
        this.onServiceOutColumnCallback = this.onServiceOutColumnCallback.bind(this);
        this.onServiceOutClick = this.onServiceOutClick.bind(this);

        this.onBodyOutCarousalCallback = this.onBodyOutCarousalCallback.bind(this);
        this.onBodyOutColumnCallback = this.onBodyOutColumnCallback.bind(this);
        this.onBodyOutClick = this.onBodyOutClick.bind(this);

        this.onDetailOutCarousalCallback = this.onDetailOutCarousalCallback.bind(this);
        this.onDetailOutColumnCallback = this.onDetailOutColumnCallback.bind(this);
        this.onDetailOutChange = this.onDetailOutChange.bind(this);

        this.onReconOutCarousalCallback = this.onReconOutCarousalCallback.bind(this);
        this.onReconOutColumnCallback = this.onReconOutColumnCallback.bind(this);
        this.onReconOutClick = this.onReconOutClick.bind(this);
        this.onReconInteriorOnlyChange = this.onReconInteriorOnlyChange.bind(this);

        this.onPhotoOutCarousalCallback = this.onPhotoOutCarousalCallback.bind(this);
        this.onPhotoOutColumnCallback = this.onPhotoOutColumnCallback.bind(this);
        this.onPhotoOutClick = this.onPhotoOutClick.bind(this);

        this.onUndoInventoryClick = this.onUndoInventoryClick.bind(this);
        this.onUndoOkClick = this.onUndoOkClick.bind(this);
        this.onCancelUndoClick = this.onCancelUndoClick.bind(this);

        this.onAddCommentColumnCallback = this.onAddCommentColumnCallback.bind(this);
        this.onToggleViewComments = this.onToggleViewComments.bind(this);
        this.onToggleAddComments = this.onToggleAddComments.bind(this);
        this.onCommentTextChange = this.onCommentTextChange.bind(this);
        this.onSaveCommentClick = this.onSaveCommentClick.bind(this);

        this.roNumberColumnCallback = this.roNumberColumnCallback.bind(this);

        this.onPublishSelectChange = this.onPublishSelectChange.bind(this);
        this.onOkPublishChange = this.onOkPublishChange.bind(this);
        this.onCancelPublishClick = this.onCancelPublishClick.bind(this);

        this.onEstCompletionDateColumnCallback = this.onEstCompletionDateColumnCallback.bind(this);
        this.onEstCompletionDateChange = this.onEstCompletionDateChange.bind(this);
        this.onSaveEstCompletionDateClick = this.onSaveEstCompletionDateClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("UsedReconditioningInventoryComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/UsedReconditioningInventoryComponent/Inventory", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("UsedReconditioningInventoryComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Used Car Prep";
    startDate = "01/01/2020";
    endDate = moment().format('MM/DD/YYYY');
    legendText = <Fragment>
        <div className="d-inline-block text-nowrap"><div className="d-inline dgdg-import-wizard-legend dgdg-used-reconditioning-bg-gray" />&nbsp;&nbsp;&nbsp; Wholesale/Do Not Publish</div>
        <div className="d-inline-block text-nowrap"><div className="d-inline dgdg-import-wizard-legend dgdg-used-reconditioning-bg-orange" />&nbsp;&nbsp;&nbsp;Pending Smog, VIN, Weight, etc</div>
        <div className="d-inline-block text-nowrap"><div className="d-inline dgdg-import-wizard-legend dgdg-used-reconditioning-estimated-date" />&nbsp;&nbsp;&nbsp;Past EDOC</div>
    </Fragment>;
    serviceHoldList = [
        { key: "PendingArrival", value: "Pending Arrival" },
        { key: "Dispatch", value: "Holding Dispatch" },
        { key: "PricingAvailability", value: "Holding Pricing & Availability" },
        { key: "PartsOrder", value: "Holding Parts Order" },
        { key: "Parts", value: "Holding Parts Arrival" },
        { key: "BackOrderParts", value: "Holding Back Order Parts" },
        { key: "Monitors", value: "Monitors" },
        { key: "AtOEMDealer", value: "At OEM Dealer" },
        { key: "Vendor", value: "Holding Vendor" },
        { key: "Authorization", value: "Holding Authorization" },
        { key: "PendingTransport", value: "Pending Transport" },
        { key: "Other", value: "Other" },
        { key: "NoHolds", value: "No Holds" }
    ];

    configureData(response, selectedStore) {
        let today = moment();
        response.forEach((responseItem, index) => {
            responseItem.ok_publish = responseItem.inventory_state === "InPrep" ? true : false;
            responseItem.service_out_due_age = responseItem.entry_date ? today.diff(responseItem.entry_date, 'days') : 0;
            responseItem.body_out_due_age = responseItem.service_out_date_time ? today.diff(responseItem.service_out_date_time, 'days') : 0;
            responseItem.detail_out_due_age = responseItem.body_out_date_time ? today.diff(responseItem.body_out_date_time, 'days') : responseItem.service_out_date_time ? today.diff(responseItem.service_out_date_time, 'days') : 0;
            responseItem.recon_out_due_age = responseItem.detail_out_date_time ? today.diff(responseItem.detail_out_date_time, 'days') : 0;
            responseItem.photo_out_due_age = responseItem.recon_out_date_time ? today.diff(responseItem.recon_out_date_time, 'days') : 0;
            responseItem.rowCssName = (responseItem.is_wholesale || responseItem.ok_publish) ? "dgdg-used-reconditioning-bg-gray" : (responseItem.is_smog_hold || responseItem.is_vin_hold || responseItem.is_weight_hold || (responseItem.service_hold_metric_name && responseItem.service_hold_metric_name !== "NoHolds")) ? "dgdg-used-reconditioning-bg-orange" : null;
            responseItem.color = responseItem.rowCssName === "dgdg-used-reconditioning-bg-gray" ? "Grey" : responseItem.rowCssName === "dgdg-used-reconditioning-bg-orange" ? "Orange" : "White";
            responseItem.est_completion_date = responseItem.est_completion_date ? moment(responseItem.est_completion_date) : null;
            responseItem.est_completion_date_cellCssName = (responseItem.est_completion_date && responseItem.est_completion_date < moment().subtract(1, "day")) ? "dgdg-used-reconditioning-estimated-date" : "";
            responseItem.metric_name = responseItem.service_hold_metric_name ? this.serviceHoldList.filter((service) => service.key === responseItem.service_hold_metric_name)[0]?.value : "Select Service Hold";
            responseItem.service_hold_metric_name_sort = !responseItem.service_out_date_time && responseItem.service_hold_metric_name ? responseItem.metric_name : "";
            responseItem.disable_wholesale = responseItem.edit_wholesale === 1 ? false : true;
            responseItem.disable_publish = !responseItem.edit_publish;
            responseItem.wholesale_label = "Retl";
            responseItem.wholesale_label2 = "Whsl";
            responseItem.cpo_label = "Not <br /> CPO";
            responseItem.cpo_label2 = "CPO";
            responseItem.ok_publish_label = "OK to <br /> Publish";
            responseItem.ok_publish_label2 = "Do Not <br /> Publish";
        });
        this.setState({
            isLoadingData: false,
            tableTitle: selectedStore === null ? "All Stores" : selectedStore.display_name,
            tableData: CommonService.addOrdinalColumn(response),
            paintTable: true,
            serviceActiveCount: response[0].service_active_count,
            bodyActiveCount: response[0].body_active_count,
            detailActiveCount: response[0].detail_active_count,
            reconActiveCount: response[0].recon_active_count,
            photoActiveCount: response[0].photo_active_count
        }, () => {
            this.setState({ paintTable: false });
        });
    }

    getComments(comments) {
        let htmlContent = [];
        if (comments !== null) {
            let commentParts = [];
            comments.split('\r').forEach(function (value) {
                commentParts.push(value.split(': '));
            });
            commentParts.forEach((commentPart) => {
                if (commentPart[1]) {
                    htmlContent.push(
                        <Fragment>
                            <span key="comment_part1" style={{ "color": "blue" }}>
                                {(commentPart[0] !== "" ? commentPart[0] + ": " : null)}
                            </span>
                            <span key="comment_part2">
                                {(commentPart[1])}
                            </span>
                        </Fragment>);
                    htmlContent.push(<br />);
                }
            });
        }

        return htmlContent;
    }

    onEstCompletionDateColumnCallback(rowData) {
        let minDate = "today";
        let disabledDates = [];
        if (rowData.est_completion_date && rowData.est_completion_date < moment().subtract(1, "day")) {
            minDate = moment(rowData.est_completion_date).format('MM/DD/YYYY');
            let currentDate = moment(rowData.est_completion_date).add(1, "day");
            while (currentDate < moment().subtract(1, "day")) {
                disabledDates.push(currentDate.format('M/D/YYYY'));
                currentDate = currentDate.add(1, "day");
            }
            rowData.est_completion_date_cellCssName = "dgdg-used-reconditioning-estimated-date";
        }
        else {
            rowData.est_completion_date_cellCssName = "";
        }

        let isDateInvalidorEmpty = !CommonService.isDateValid(rowData.est_completion_date);

        return <Fragment>
            <div className="d-inline-flex">
                <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id={"est_" + rowData.stock_number} value={rowData.est_completion_date} minDate={minDate} onDateChange={this.onEstCompletionDateChange} additionalData={rowData} disabledDates={disabledDates} />
                <button type="button" className="btn btn-link dgdg-font-size-18" disabled={isDateInvalidorEmpty} onClick={(event) => this.onSaveEstCompletionDateClick(event, rowData)}>
                    <span className="fas fa-save" />
                </button>
            </div>
            <br />
            <span> {rowData.est_completion_by ? ("Updated by: " + rowData.est_completion_by) : ""} </span>
        </Fragment>
    }

    onEstCompletionDateChange(id, value, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.est_completion_date = value ? moment(value) : null;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    saveService(inputData, rowData) {
        let tableData = this.state.tableData;
        try {
            rowData.service_out_date_time_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/saveService", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            return fetch("/UsedReconditioning/SaveInventoryReconServiceOut", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    let serviceActiveCount = this.state.serviceActiveCount;
                    let detailActiveCount = this.state.detailActiveCount
                    if (inputData.dateTime) {
                        serviceActiveCount--;
                    }
                    else {
                        serviceActiveCount++;
                    }

                    detailActiveCount = inputData.dateTime ? detailActiveCount + 1 : detailActiveCount - 1;
                    rowData.detail_in_date_time = inputData.dateTime;
                    rowData.detail_in_by = inputData.dateTime ? this.props.remoteUpn : null;
                    rowData.service_in_date_time = inputData.inDateTime;
                    rowData.service_out_date_time = inputData.dateTime;
                    rowData.service_out_by = inputData.dateTime ? this.props.remoteUpn : null;
                    rowData.can_undo_service_out_date_time = inputData.dateTime ? true : false;
                    rowData.service_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        serviceActiveCount: serviceActiveCount,
                        detailActiveCount: detailActiveCount,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/SaveInventoryReconServiceOut", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    saveDetail(inputData, rowData) {
        let tableData = this.state.tableData;
        try {
            rowData.detail_out_date_time_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/saveDetail", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            return fetch("/UsedReconditioning/SaveInventoryReconDetailOut", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    let detailActiveCount = this.state.detailActiveCount;
                    let bodyActiveCount = this.state.bodyActiveCount;
                    let reconActiveCount = this.state.reconActiveCount
                    if (inputData.dateTime) {
                        detailActiveCount--;
                    }
                    else {
                        detailActiveCount++;
                    }
                    if (inputData.outMetricName === "Body") {
                        bodyActiveCount = inputData.dateTime ? bodyActiveCount + 1 : bodyActiveCount - 1;
                        rowData.body_in_date_time = inputData.dateTime;
                        rowData.body_in_by = inputData.dateTime ? this.props.remoteUpn : null;
                    }
                    else {
                        reconActiveCount = inputData.dateTime ? reconActiveCount + 1 : reconActiveCount - 1;
                        rowData.recon_in_date_time = inputData.dateTime;
                        rowData.recon_in_by = inputData.dateTime ? this.props.remoteUpn : null;
                    }
                    rowData.detail_out_date_time = inputData.dateTime;
                    rowData.detail_out_by = inputData.dateTime ? this.props.remoteUpn : null;
                    rowData.can_undo_service_out_date_time = inputData.dateTime ? false : true;;
                    rowData.can_undo_detail_out_date_time = inputData.dateTime ? true : false;
                    rowData.detail_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        detailActiveCount: detailActiveCount,
                        bodyActiveCount: bodyActiveCount,
                        reconActiveCount: reconActiveCount,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/SaveInventoryReconDetailOut", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    saveBody(inputData, rowData) {
        let tableData = this.state.tableData;
        try {
            rowData.body_out_date_time_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/saveBody", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            return fetch("/UsedReconditioning/SaveInventoryReconBodyOut", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    let reconActiveCount = this.state.reconActiveCount;
                    let bodyActiveCount = this.state.bodyActiveCount;
                    if (inputData.dateTime) {
                        bodyActiveCount--;
                        reconActiveCount++;
                    }
                    else {
                        bodyActiveCount++;
                        reconActiveCount--;
                    }
                    rowData.body_out_date_time = inputData.dateTime;
                    rowData.body_out_by = inputData.dateTime ? this.props.remoteUpn : null;
                    rowData.can_undo_body_out_date_time = inputData.dateTime ? true : false
                    rowData.can_undo_detail_out_date_time = inputData.dateTime ? false : true;
                    rowData.recon_in_date_time = inputData.dateTime;
                    rowData.recon_in_by = inputData.dateTime ? this.props.remoteUpn : null;
                    rowData.body_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        bodyActiveCount: bodyActiveCount,
                        reconActiveCount: reconActiveCount,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.body_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/SaveInventoryReconBodyOut", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    saveRecon(inputData, rowData) {
        let tableData = this.state.tableData;
        try {
            rowData.recon_out_date_time_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/saveRecon", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            return fetch("/UsedReconditioning/SaveInventoryReconReconOut", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    let reconActiveCount = this.state.reconActiveCount;
                    let photoActiveCount = this.state.photoActiveCount;
                    if (inputData.dateTime) {
                        reconActiveCount--;
                        photoActiveCount++;
                    }
                    else {
                        reconActiveCount++;
                        photoActiveCount--;
                    }

                    rowData.recon_out_date_time = inputData.dateTime;
                    rowData.recon_out_by = inputData.dateTime ? this.props.remoteUpn : null;
                    rowData.can_undo_service_out_date_time = false;
                    rowData.can_undo_detail_out_date_time = !rowData.body_in_date_time && !inputData.dateTime ? true : false;
                    rowData.can_undo_body_out_date_time = inputData.dateTime ? false : true
                    rowData.can_undo_recon_out_date_time = inputData.dateTime ? true : false;
                    rowData.photo_in_date_time = inputData.dateTime;
                    rowData.photo_in_by = inputData.dateTime ? this.props.remoteUpn : null;
                    rowData.recon_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        reconActiveCount: reconActiveCount,
                        photoActiveCount: photoActiveCount,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/SaveInventoryReconReconOut", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    savePhoto(inputData, rowData) {
        let tableData = this.state.tableData;
        try {
            rowData.photo_out_date_time_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/savePhoto", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            return fetch("/UsedReconditioning/SaveInventoryReconPhotoOut", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    let photoActiveCount = this.state.photoActiveCount;
                    if (inputData.dateTime) {
                        photoActiveCount--;
                    }
                    else {
                        photoActiveCount++;
                    }
                    rowData.photo_out_date_time = inputData.dateTime;
                    rowData.photo_out_by = inputData.dateTime ? this.props.remoteUpn : null;
                    rowData.can_undo_service_out_date_time = false;
                    rowData.can_undo_detail_out_date_time = false;
                    rowData.can_undo_recon_out_date_time = inputData.dateTime ? false : true;
                    rowData.can_undo_photo_out_date_time = inputData.dateTime ? true : false;
                    rowData.photo_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        photoActiveCount: photoActiveCount,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/SaveInventoryReconPhotoOut", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveEstCompletionDateClick(event, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.est_completion_date_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = {
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                estimatedCompletionDate: rowData.est_completion_date ? CommonService.formatDate(rowData.est_completion_date) : null
            };
            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/onSaveEstCompletionDateClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedReconditioning/SaveEstimatedCompletionDate", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.est_completion_by = this.props.remoteUpn;
                    rowData.est_completion_date_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.est_completion_date_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/SaveEstimatedCompletionDate", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(selectedStore) {
        try {
            this.setState({
                isLoadingData: true,
                selectedStore: selectedStore
            });
            let inputData = {
                storeNumber: selectedStore ? selectedStore.store_number : null
            };
            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedReconditioning/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.configureData(parsedResponse, selectedStore);
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/GetReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("UsedReconidioningInvetoryComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onWholesaleSelectChange(id, isChecked, rowData) {
        try {
            rowData.is_wholesale = isChecked;
            this.setState({
                wholesaleRow: rowData,
                showWholesaleConfirmation: true
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onOkWholesaleChange() {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === this.state.wholesaleRow.stock_number)[0];
        try {
            rowData.is_wholesale = this.state.wholesaleRow.is_wholesale;
            rowData.is_wholesale_showSpinner = true;

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({
                    paintTable: false,
                    showWholesaleConfirmation: false
                });
            });

            let inputData = {
                stockNumber: this.state.wholesaleRow.stock_number,
                isWholesale: this.state.wholesaleRow.is_wholesale
            };
            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/onOkWholesaleChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedReconditioning/SaveWholesale", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.configureData(tableData, this.state.selectedStore);
                    rowData.is_wholesale_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.is_wholesale_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/onOkWholesaleChange", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({ isLoadingData: false });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelWholesaleClick() {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === this.state.wholesaleRow.stock_number)[0];
        rowData.is_wholesale = this.state.wholesaleRow.is_wholesale ? false : true;
        try {
            this.setState({
                tableData: tableData,
                showWholesaleConfirmation: false,
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCPOSelectChange(id, isChecked, rowData) {
        try {
            rowData.is_cpo = isChecked;
            this.setState({
                cpoRow: rowData,
                showCPOConfirmation: true
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onOkCPOChange() {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === this.state.cpoRow.stock_number)[0];
        try {
            rowData.is_cpo = this.state.cpoRow.is_cpo;
            rowData.is_cpo_showSpinner = true;

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({
                    paintTable: false,
                    showCPOConfirmation: false
                });
            });

            let inputData = {
                stockNumber: this.state.cpoRow.stock_number,
                isCPO: this.state.cpoRow.is_cpo
            };
            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/onOkCPOChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedReconditioning/SaveCPO", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.configureData(tableData, this.state.selectedStore);
                    rowData.is_cpo_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.is_cpo_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/onOkCPOChange", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({ isLoadingData: false });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelCPOClick() {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === this.state.cpoRow.stock_number)[0];
        rowData.is_cpo = this.state.cpoRow.is_cpo ? false : true;
        try {
            this.setState({
                tableData: tableData,
                showCPOConfirmation: false,
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onServiceOutCarousalCallback(rowData) {
        let text = "Pending";
        let status = "pending";
        let statusOut = "";
        if (rowData.service_out_date_time) {
            text = "Completed";
            status = "completed";
            if (rowData.detail_out_date_time) {
                statusOut = "completed";
            } else {
                statusOut = "pending";
            }
        }

        return <DGDGCarouselComponent text={text} status={status} statusOut={statusOut} />;
    }

    onServiceOutColumnCallback(rowData) {
        let rowId = "serviceOutDate_" + rowData.stock_number;
        if (rowData.service_out_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold)
            return <Fragment>
                {CommonService.formatDate(moment(rowData.service_out_date_time))}
                <span className="dgdg-used-reconditioning-label">
                    {" (" + rowData.service_out_age + " days)"}
                    <br />
                    {"By " + rowData.service_out_by.replace("@dgdgeng.com", "").replace("@dgdg.com", "").replace(".", " ")}
                </span>
                {
                    rowData.can_undo_service_out_date_time
                        ? <button type="button" className="btn btn-link dgdg-used-reconditioning-undo-button" onClick={(event) => { if (rowData.is_wholesale) return false; this.onUndoInventoryClick(event, rowData, "Service Out"); }}>
                            <span className="fas fa-undo" />
                        </button>
                        : null
                }
            </Fragment>;
        else {
            let disabled = rowData.edit_service === 0 || (rowData.service_out_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold);
            let buttonDisabled = rowData.is_wholesale || disabled || rowData.is_parts_hold || rowData.is_smog_hold || rowData.is_vin_hold || rowData.is_weight_hold;
            let CssName = "btn btn-success";
            if (rowData.service_out_due_age >= 4)
                CssName = "btn btn-danger";
            else if (buttonDisabled)
                CssName = "btn btn-primary";

            return <Fragment>
                <div className="form-inline dgdg-used-reconditioning-service-out">
                    <DGDGFormCheckboxV2Component label="Smog:" disabled={rowData.is_wholesale || disabled} id={rowId + "_smogHold"} value={rowData.is_smog_hold} onChange={this.onSmogHoldChange} />
                    &nbsp;&nbsp;
                    <DGDGFormCheckboxV2Component label="VIN:" cssName="dgdg-used-reconditioning-body-hold-toggle" disabled={rowData.is_wholesale || disabled} id={rowId + "_vinHold"} value={rowData.is_vin_hold} onChange={this.onVinHoldChange} />
                    &nbsp;&nbsp;
                    <DGDGFormCheckboxV2Component label="Weight:" cssName="dgdg-used-reconditioning-body-hold-toggle" disabled={rowData.is_wholesale || disabled} id={rowId + "_weightHold"} value={rowData.is_weight_hold} onChange={this.onWeightHoldChange} />
                </div>
                <div className="form-inline" style={{ "marginBottom": "5px" }}>
                    <DGDGFormDropdownComponent data={this.serviceHoldList} label="Hold" itemKey="value" id={rowId + "_service_hold"} disabled={rowData.is_wholesale || disabled} onItemClick={this.onServiceHoldChange} value={rowData.metric_name} />
                </div>
                <div className="text-center">
                    {
                        !buttonDisabled && (!rowData.service_hold_metric_name || rowData.service_hold_metric_name === "NoHolds")
                            ? <button type="button" className={CssName + " dgdg-button-width"} onClick={(event) => this.onServiceOutClick(event, rowData)}>Service Out</button>
                            : null
                    }
                    <div className="dgdg-used-reconditioning-label">
                        ({rowData.service_out_due_age + " days"})
                    </div>
                </div>
            </Fragment>;
        }
    }

    onSmogHoldChange(id, value) {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === id.split('_')[1])[0];
        try {
            rowData.service_out_date_time_showSpinner = true;
            rowData.is_smog_hold = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                metricName: "Smog",
                inDateTime: value ? moment() : null,
                outDateTime: value ? null : moment()
            };

            let url = "";
            if (value)
                url = "/UsedReconditioning/SaveInventoryReconTimingIn";
            else
                url = "/UsedReconditioning/SaveInventoryReconTimingOut";

            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/onSmogHoldChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.configureData(tableData, this.state.selectedStore);
                    rowData.service_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.service_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/SaveInventoryReconTimingOut", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onVinHoldChange(id, value) {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === id.split('_')[1])[0];
        try {
            rowData.is_vin_hold = value;
            rowData.service_out_date_time_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                metricName: "VIN",
                inDateTime: value ? moment() : null,
                outDateTime: value ? null : moment()
            };

            let url = "";
            if (value)
                url = "/UsedReconditioning/SaveInventoryReconTimingIn";
            else
                url = "/UsedReconditioning/SaveInventoryReconTimingOut";

            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/onVinHoldChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.configureData(tableData, this.state.selectedStore);
                    rowData.service_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.service_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/SaveInventoryReconTimingIn", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onWeightHoldChange(id, value) {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === id.split('_')[1])[0];

        try {
            rowData.is_weight_hold = value;
            rowData.service_out_date_time_showSpinner = true;

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                metricName: "Weight",
                inDateTime: value ? moment() : null,
                outDateTime: value ? null : moment()
            };

            let url = "";
            if (value)
                url = "/UsedReconditioning/SaveInventoryReconTimingIn";
            else
                url = "/UsedReconditioning/SaveInventoryReconTimingOut";

            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/onWeightHoldChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.configureData(tableData, this.state.selectedStore);
                    rowData.service_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.service_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/SaveInventoryReconTimingIn", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onServiceHoldChange(id, selectedItem) {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === id.split('_')[1])[0];
        try {
            rowData.service_out_date_time_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                inMetricName: selectedItem.key,
                outMetricName: rowData.service_hold_metric_name,
                dateTime: moment()
            };
            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/onServiceHoldChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedReconditioning/SaveInventoryServiceHold", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(parsedResponse => {
                    rowData.service_out_date_time_showSpinner = false;
                    rowData.service_hold_metric_name = selectedItem.key;
                    rowData.metric_name = selectedItem.value;
                    this.configureData(tableData, this.state.selectedStore);
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/SaveInventoryServiceHold", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onServiceOutClick(event, rowData) {
        try {
            let inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                year: rowData.year,
                make: rowData.make,
                model: rowData.model,
                inDateTime: moment(rowData.entry_date),
                dateTime: moment(),
            }
            this.saveService(inputData, rowData);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDetailOutCarousalCallback(rowData) {
        if (!rowData.service_out_date_time) {
            return null;
        }

        let text = "Pending";
        let status = "pending";
        let statusIn = "pending";
        let statusOut = "";
        if (rowData.detail_out_date_time) {
            text = "Completed";
            status = "completed";
            statusIn = "completed";
            if (rowData.body_out_date_time || rowData.recon_in_date_time) {
                text = "Completed";
                statusOut = "completed";
            } else {
                statusOut = "pending";
            }
        }

        return <DGDGCarouselComponent text={text} status={status} statusIn={statusIn} statusOut={statusOut} />;
    }

    onDetailOutColumnCallback(rowData) {
        if (rowData.detail_out_date_time)
            return <Fragment>
                {CommonService.formatDate(moment(rowData.detail_out_date_time))}
                <span className="dgdg-used-reconditioning-label">
                    {" (" + (rowData.detail_out_age === null ? 0 : rowData.detail_out_age) + " days)"}
                    <br />
                    {"By " + rowData.detail_out_by.replace("@dgdgeng.com", "").replace("@dgdg.com", "").replace(".", " ")}
                </span>
                {
                    rowData.can_undo_detail_out_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold
                        ? <button type="button" className="btn btn-link dgdg-used-reconditioning-undo-button" onClick={(event) => { if (rowData.is_wholesale) return false; this.onUndoInventoryClick(event, rowData, "Detail Out"); }}>
                            <span className="fas fa-undo" />
                        </button>
                        : null
                }
            </Fragment>;
        else {
            let CssName = "btn btn-success";
            let disabled = (rowData.edit_detail === 0 || !(rowData.service_out_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold) || rowData.detail_out_date_time) || rowData.is_wholesale;
            if (rowData.detail_out_due_age >= 2)
                CssName = "btn btn-danger";
            else if (disabled)
                CssName = "btn btn-primary";

            return <Fragment>
                <div className="text-center">
                    {
                        !disabled
                            ? <Fragment>
                                <div className="d-inline dropdown">
                                    <button className={CssName + " dgdg-button-width dropdown-toggle"} type="button" id="detailOutBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Detail Out
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="detailOutBtn">
                                        <button className={CssName + " dgdg-button-width dropdown-item"} onClick={(event) => this.onDetailOutChange(rowData, "Body")}>
                                            Detail Out to Body
                                        </button>
                                        <button className={CssName + " dgdg-button-width dropdown-item"} onClick={(event) => this.onDetailOutChange(rowData, "Recon")}>
                                            Detail Out to Recon
                                        </button>
                                    </div>
                                </div>
                                <br />
                                <span className="dgdg-used-reconditioning-label">
                                    ({rowData.detail_out_due_age + " days"})
                                </span>
                            </Fragment>
                            : <label>N/A</label>
                    }
                </div>
            </Fragment>;
        }
    }

    onDetailOutChange(rowData, detailOutBtn) {
        try {
            let inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                year: rowData.year,
                make: rowData.make,
                model: rowData.model,
                outMetricName: detailOutBtn,
                dateTime: moment(),
            }
            this.saveDetail(inputData, rowData);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onBodyOutCarousalCallback(rowData) {
        if (!rowData.detail_out_date_time) {
            return null;
        }

        let text = "Pending";
        let status = "pending";
        let statusIn = "pending";
        let statusOut = "";
        if (rowData.body_out_date_time || rowData.recon_in_date_time) {
            text = "Completed";
            status = "completed";
            statusIn = "completed";
            if (rowData.recon_out_date_time) {
                statusOut = "completed";
            } else {
                statusOut = "pending";
            }
        }

        return <DGDGCarouselComponent text={text} status={status} statusIn={statusIn} statusOut={statusOut} />;
    }

    onBodyOutColumnCallback(rowData) {
        if (rowData.body_out_date_time)
            return <Fragment>
                {CommonService.formatDate(moment(rowData.body_out_date_time))}
                <span className="dgdg-used-reconditioning-label">
                    {" (" + (rowData.body_out_age === null ? 0 : rowData.body_out_age) + " days)"}
                    <br />
                    {"By " + rowData.body_out_by.replace("@dgdgeng.com", "").replace("@dgdg.com", "").replace(".", " ")}
                </span>
                {
                    rowData.can_undo_body_out_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold
                        ? <button type="button" className="btn btn-link dgdg-used-reconditioning-undo-button" onClick={(event) => { if (rowData.is_wholesale) return false; this.onUndoInventoryClick(event, rowData, "Body Out"); }}>
                            <span className="fas fa-undo" />
                        </button>
                        : null
                }
            </Fragment>;
        else {
            let CssName = "btn btn-success";
            let disabled = (rowData.edit_detail === 0 || !(rowData.service_out_date_time && rowData.body_in_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold) || rowData.body_out_date_time) || rowData.is_wholesale;
            if (rowData.body_out_due_age >= 2)
                CssName = "btn btn-danger";
            else if (disabled)
                CssName = "btn btn-primary";

            return <Fragment>
                <div className="text-center">
                    {
                        !disabled
                            ? <Fragment>
                                <button type="button" className={CssName + " dgdg-button-width"} onClick={(event) => this.onBodyOutClick(event, rowData)}>Body Out</button>
                                <br />
                                <span className="dgdg-used-reconditioning-label">
                                    ({rowData.body_out_due_age + " days"})
                                </span>
                            </Fragment>
                            : <label>N/A</label>
                    }
                </div>
            </Fragment>;
        }
    }

    onBodyOutClick(event, rowData) {
        try {
            let inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                year: rowData.year,
                make: rowData.make,
                model: rowData.model,
                dateTime: moment()
            }
            this.saveBody(inputData, rowData);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onReconOutCarousalCallback(rowData) {
        if (!rowData.detail_out_date_time || (!rowData.body_out_date_time && !rowData.recon_in_date_time)) {
            return null;
        }

        let text = "Pending";
        let status = "pending";
        let statusIn = "pending";
        let statusOut = "";
        if (rowData.recon_out_date_time) {
            text = "Completed";
            status = "completed";
            statusIn = "completed";
            if (rowData.photo_out_date_time) {
                statusOut = "completed";
            } else {
                statusOut = "pending";
            }
        }

        return <DGDGCarouselComponent text={text} status={status} statusIn={statusIn} statusOut={statusOut} />;
    }

    onReconOutColumnCallback(rowData) {
        let rowId = "reconOutDate_" + rowData.stock_number;
        if (rowData.recon_out_date_time)
            return <Fragment>
                {CommonService.formatDate(moment(rowData.recon_out_date_time))}
                <span className="dgdg-used-reconditioning-label">
                    {" (" + (rowData.recon_out_age === null ? 0 : rowData.recon_out_age) + " days)"}
                    <br />
                    {"By " + rowData.recon_out_by.replace("@dgdgeng.com", "").replace("@dgdg.com", "").replace(".", " ")}
                </span>
                {
                    rowData.can_undo_recon_out_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold
                        ? <button type="button" className="btn btn-link dgdg-used-reconditioning-undo-button" onClick={(event) => { if (rowData.is_wholesale) return false; this.onUndoInventoryClick(event, rowData, "Recon Out"); }}>
                            <span className="fas fa-undo" />
                        </button>
                        : null
                }
            </Fragment>;
        else {
            let disabled = (rowData.edit_recon === 0 || !(rowData.service_out_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold) || !rowData.detail_out_date_time || rowData.recon_out_date_time) || rowData.is_wholesale;
            let CssName = "btn btn-success";
            if (rowData.recon_out_due_age >= 2)
                CssName = "btn btn-danger";
            else if (disabled)
                CssName = "btn btn-primary";

            return <Fragment>
                <div className="text-center">
                    {
                        !disabled && rowData.recon_in_date_time
                            ? <Fragment>
                                <div className="form-inline">
                                    <DGDGFormCheckboxComponent label="Interior Only" id={rowId + "_ReconInteriorOnly"} value={rowData.recon_interior_only} onChange={this.onReconInteriorOnlyChange} />
                                </div>
                                <button type="button" className={CssName + " dgdg-button-width"} onClick={(event) => this.onReconOutClick(event, rowData)}>Recon Out</button>
                                <br />
                                <span className="dgdg-used-reconditioning-label">
                                    ({rowData.recon_out_due_age + " days"})
                                </span>
                            </Fragment>
                            : <label>N/A</label>
                    }
                </div>
            </Fragment>;
        }
    }

    onReconInteriorOnlyChange(id, value) {
        try {
            let stockNumber = id.split('_')[1];
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.stock_number === stockNumber)[0];
            rowData.recon_interior_only = value;

            let inputData = {
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                isReconInteriorOnly: value
            };
            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/onReconInteriorOnlyChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedReconditioning/SaveReconInteriorOnly", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.recon_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.recon_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onReconOutClick(event, rowData) {
        try {
            let inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                year: rowData.year,
                make: rowData.make,
                model: rowData.model,
                dateTime: moment()
            }
            this.saveRecon(inputData, rowData);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPhotoOutCarousalCallback(rowData) {
        if (!rowData.recon_out_date_time) {
            return null;
        }

        let text = "Pending";
        let status = "pending";
        let statusIn = "pending";
        if (rowData.photo_out_date_time) {
            text = "Completed";
            status = "completed";
            statusIn = "completed";
        }

        return <DGDGCarouselComponent text={text} status={status} statusIn={statusIn} />;
    }

    onPhotoOutColumnCallback(rowData) {
        if (rowData.photo_out_date_time)
            return <Fragment>
                {CommonService.formatDate(moment(rowData.photo_out_date_time))}
                <span className="dgdg-used-reconditioning-label">
                    {" (" + (rowData.photo_out_age === null ? 0 : rowData.photo_out_age) + " days)"}
                    <br />
                    {"By " + rowData.photo_out_by.replace("@dgdgeng.com", "").replace("@dgdg.com", "").replace(".", " ")}
                </span>
                {
                    rowData.can_undo_photo_out_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold
                        ? <button type="button" className="btn btn-link dgdg-used-reconditioning-undo-button" onClick={(event) => { if (rowData.is_wholesale) return false; this.onUndoInventoryClick(event, rowData, "Photo Out"); }}>
                            <span className="fas fa-undo" />
                        </button>
                        : null
                }
            </Fragment>;
        else {
            let disabled = (rowData.edit_photo === 0 || !(rowData.service_out_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold) || !rowData.detail_out_date_time || !rowData.recon_out_date_time || rowData.photo_out_date_time) || rowData.is_wholesale;
            let CssName = "btn btn-success";
            if (rowData.photo_out_due_age >= 1)
                CssName = "btn btn-danger";
            else if (disabled)
                CssName = "btn btn-primary";

            return <Fragment>
                <div className="text-center">
                    {
                        !disabled
                            ? <Fragment>
                                <button type="button" className={CssName + " dgdg-button-width"} onClick={(event) => this.onPhotoOutClick(event, rowData)}>Photo Out</button>
                                <br />
                                <span className="dgdg-used-reconditioning-label">
                                    ({rowData.photo_out_due_age + " days"})
                                </span>
                            </Fragment>
                            : <label>N/A</label>
                    }
                </div>
            </Fragment>;
        }
    }

    onPhotoOutClick(event, rowData) {
        try {
            let inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                year: rowData.year,
                make: rowData.make,
                model: rowData.model,
                dateTime: moment()
            }
            this.savePhoto(inputData, rowData);

        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onUndoInventoryClick(event, rowData, actionName) {
        try {
            this.setState({
                undoDetail: rowData,
                actionName: actionName
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onUndoOkClick(event) {
        let inputData = {
            storeNumber: this.state.undoDetail.store_number,
            stockNumber: this.state.undoDetail.stock_number,
            vin: this.state.undoDetail.vin,
            year: this.state.undoDetail.year,
            make: this.state.undoDetail.make,
            model: this.state.undoDetail.model,
            dateTime: null
        }
        switch (this.state.actionName) {
            case "Service Out":
                inputData.outDateTime = this.state.undoDetail.service_out_date_time;
                inputData.outBy = this.state.undoDetail.service_out_by;
                this.saveService(inputData, this.state.undoDetail);
                break;
            case "Detail Out":
                inputData.outMetricName = this.state.undoDetail.body_in_date_time == null ? "Recon" : "Body";
                inputData.outDateTime = this.state.undoDetail.detail_out_date_time;
                inputData.outBy = this.state.undoDetail.detail_out_by;
                this.saveDetail(inputData, this.state.undoDetail);
                break;
            case "Body Out":
                inputData.outDateTime = this.state.undoDetail.body_out_date_time;
                inputData.outBy = this.state.undoDetail.body_out_by;
                this.saveBody(inputData, this.state.undoDetail);
                break;
            case "Recon Out":
                inputData.outDateTime = this.state.undoDetail.recon_out_date_time;
                inputData.outBy = this.state.undoDetail.recon_out_by;
                this.saveRecon(inputData, this.state.undoDetail);
                break;
            case "Photo Out":
                inputData.outDateTime = this.state.undoDetail.photo_out_date_time;
                inputData.outBy = this.state.undoDetail.photo_out_by;
                this.savePhoto(inputData, this.state.undoDetail);
                break;
            default:
                this.onCancelUndoClick();
                break;
        }
        this.onCancelUndoClick();
    }

    onCancelUndoClick(event) {
        try {
            this.setState({ undoDetail: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddCommentColumnCallback(rowData) {
        let comments = this.getComments(rowData.comments);
        let disableAddComments = !rowData.edit_comment;
        return <Fragment>
            <button key="btnViewComments" type="button" className="btn btn-link" onClick={(event) => this.onToggleViewComments(event, rowData)}>{comments.length / 2} Comments
                {
                    rowData.showViewComments
                        ? <span className="far fa-sort-up dgdg-icon" />
                        : <span className="far fa-sort-down dgdg-icon" />
                }
            </button>
            <button key="btnAddComment" type="button" className="btn btn-link dgdg-icon" disabled={disableAddComments} onClick={(event) => this.onToggleAddComments(event, rowData)}>Add Comments
                {
                    rowData.showAddComment
                        ? <span className="fas fa-times text-danger dgdg-icon" />
                        : <span className="fas fa-plus dgdg-icon" />
                }
            </button>
            <br />
            {
                rowData.showViewComments
                    ? comments
                    : null
            }
            {
                rowData.showAddComment
                    ? <div className="form-inline">
                        <DGDGFormTextAreaComponent id={"comments_" + rowData.stock_number} value={rowData["comments_" + rowData.stock_number]} rows="3" onChange={this.onCommentTextChange} colSpan="col-11" controlSpan="col-12" />
                        <button type="button" className="btn btn-link dgdg-font-size-18" disabled={disableAddComments} onClick={(event) => this.onSaveCommentClick(event, rowData)}>
                            <span className="fas fa-save" />
                        </button>
                        <DGDGSpinnerComponent showSpinner={rowData.comments_showSpinner} />
                    </div>
                    : null
            }
        </Fragment>;
    }

    onToggleViewComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showViewComments = rowData.showViewComments ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });
    }

    onToggleAddComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showAddComment = rowData.showAddComment ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
            if (rowData.showAddComment) {
                let id = "#comments_" + rowData.stock_number;
                $(id).focus();
            }
        });
    }

    onCommentTextChange(id, value) {
        try {
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.stock_number === id.split('_')[1])[0];
            rowData["comments_" + rowData.stock_number] = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveCommentClick(event, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.comments_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                comments: rowData["comments_" + rowData.stock_number].replace(/\n|\r/g, " ")
            };
            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/onSaveCommentClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedReconditioning/SaveComments", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.comments = moment().format("MM/DD/YYYY hh:mm A") + ", " + this.props.remoteUpn + ": " + rowData["comments_" + rowData.stock_number] + (rowData.comments ? '\r' + rowData.comments : "");
                    rowData["comments_" + rowData.stock_number] = "";
                    rowData.comments_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.comments_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    roNumberColumnCallback(rowData) {
        return <Fragment>
            <a href={"/RO/" + rowData.store_number + "/" + rowData.recon_ro_number} target="_self">{rowData.recon_ro_number}</a>
            <br />
            <a href={"/RO/All/All/All/" + rowData.vin} className="text-nowrap" target="_self">List VIN ROs</a>
        </Fragment>
    }

    onPublishSelectChange(id, isChecked, rowData) {
        try {
            rowData.ok_publish = isChecked;
            this.setState({
                publishRow: rowData,
                showPublishConfirmation: true,
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onOkPublishChange() {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === this.state.publishRow.stock_number)[0];
        try {
            rowData.ok_publish = this.state.publishRow.ok_publish;
            rowData.ok_publish_showSpinner = true;
            rowData.inventory_state = this.state.publishRow.ok_publish === true ? "InPrep" : "Live";

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
                this.onCancelPublishClick();
            });

            let inputData = {
                stockNumber: this.state.publishRow.stock_number,
                homenetState: rowData.inventory_state
            };
            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/onOkPublishChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedReconditioning/SaveHomenetState", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.configureData(tableData, this.state.selectedStore);
                    rowData.ok_publish_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.ok_publish_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/SaveHomenetState", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({ isLoadingData: false });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelPublishClick() {
        try {
            this.setState({
                showPublishConfirmation: false,
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <UsedReconditioningInventoryQueryComponent
                applicationInsights={this.props.applicationInsights}
                stores={this.props.stores} isLoadingData={this.state.isLoadingData}
                onGetReportClick={this.onGetReportClick}
            />
            <div className="dgdg-desktop dgdg-site-scrollable-content">
                <UsedReconditioningInventoryDesktopComponent
                    applicationInsights={this.props.applicationInsights}

                    tableData={this.state.tableData}
                    tableTitle={this.state.tableTitle}
                    paintTable={this.state.paintTable}
                    legendText={this.legendText}

                    serviceActiveCount={this.state.serviceActiveCount}
                    bodyActiveCount={this.state.bodyActiveCount}
                    detailActiveCount={this.state.detailActiveCount}
                    reconActiveCount={this.state.reconActiveCount}
                    photoActiveCount={this.state.photoActiveCount}

                    actionName={this.state.actionName}

                    wholesaleRow={this.state.wholesaleRow}
                    showWholesaleConfirmation={this.state.showWholesaleConfirmation}
                    publishRow={this.state.publishRow}
                    showPublishConfirmation={this.state.showPublishConfirmation}

                    cpoRow={this.state.cpoRow}
                    showCPOConfirmation={this.state.showCPOConfirmation}

                    undoDetail={this.state.undoDetail}

                    onCopyTableCallBack={this.onCopyTableCallBack}

                    onServiceOutCarousalCallback={this.onServiceOutCarousalCallback}
                    onServiceOutColumnCallback={this.onServiceOutColumnCallback}

                    onDetailOutCarousalCallback={this.onDetailOutCarousalCallback}
                    onDetailOutColumnCallback={this.onDetailOutColumnCallback}

                    onBodyOutCarousalCallback={this.onBodyOutCarousalCallback}
                    onBodyOutColumnCallback={this.onBodyOutColumnCallback}

                    onReconOutCarousalCallback={this.onReconOutCarousalCallback}
                    onReconOutColumnCallback={this.onReconOutColumnCallback}

                    onPhotoOutCarousalCallback={this.onPhotoOutCarousalCallback}
                    onPhotoOutColumnCallback={this.onPhotoOutColumnCallback}

                    onPublishSelectChange={this.onPublishSelectChange}
                    onOkPublishChange={this.onOkPublishChange}
                    onCancelPublishClick={this.onCancelPublishClick}

                    onWholesaleSelectChange={this.onWholesaleSelectChange}
                    onOkWholesaleChange={this.onOkWholesaleChange}
                    onCancelWholesaleClick={this.onCancelWholesaleClick}

                    onCPOSelectChange={this.onCPOSelectChange}
                    onOkCPOChange={this.onOkCPOChange}
                    onCancelCPOClick={this.onCancelCPOClick}

                    onUndoOkClick={this.onUndoOkClick}
                    onCancelUndoClick={this.onCancelUndoClick}

                    onAddCommentColumnCallback={this.onAddCommentColumnCallback}

                    onEstCompletionDateColumnCallback={this.onEstCompletionDateColumnCallback}
                    onEstCompletionDateChange={this.onEstCompletionDateChange}

                    roNumberColumnCallback={this.roNumberColumnCallback}
                />
            </div>
            <div className="dgdg-mobile dgdg-site-scrollable-content">
                <UsedReconditioningInventoryMobileComponent
                    applicationInsights={this.props.applicationInsights}

                    tableData={this.state.tableData}
                    tableTitle={this.state.tableTitle}
                    paintTable={this.state.paintTable}
                    legendText={this.legendText}

                    serviceActiveCount={this.state.serviceActiveCount}
                    serviceOutData={this.state.serviceOutData}

                    bodyActiveCount={this.state.bodyActiveCount}
                    detailActiveCount={this.state.detailActiveCount}
                    reconActiveCount={this.state.reconActiveCount}
                    photoActiveCount={this.state.photoActiveCount}

                    actionName={this.state.actionName}

                    wholesaleRow={this.state.wholesaleRow}
                    showWholesaleConfirmation={this.state.showWholesaleConfirmation}
                    publishRow={this.state.publishRow}
                    showPublishConfirmation={this.state.showPublishConfirmation}

                    cpoRow={this.state.cpoRow}
                    showCPOConfirmation={this.state.showCPOConfirmation}

                    undoDetail={this.state.undoDetail}

                    onCopyTableCallBack={this.onCopyTableCallBack}

                    onServiceOutCarousalCallback={this.onServiceOutCarousalCallback}
                    onServiceOutColumnCallback={this.onServiceOutColumnCallback}

                    onDetailOutCarousalCallback={this.onDetailOutCarousalCallback}
                    onDetailOutColumnCallback={this.onDetailOutColumnCallback}

                    onBodyOutCarousalCallback={this.onBodyOutCarousalCallback}
                    onBodyOutColumnCallback={this.onBodyOutColumnCallback}

                    onReconOutCarousalCallback={this.onReconOutCarousalCallback}
                    onReconOutColumnCallback={this.onReconOutColumnCallback}

                    onPhotoOutCarousalCallback={this.onPhotoOutCarousalCallback}
                    onPhotoOutColumnCallback={this.onPhotoOutColumnCallback}

                    onPublishSelectChange={this.onPublishSelectChange}
                    onOkPublishChange={this.onOkPublishChange}
                    onCancelPublishClick={this.onCancelPublishClick}

                    onWholesaleSelectChange={this.onWholesaleSelectChange}
                    onOkWholesaleChange={this.onOkWholesaleChange}
                    onCancelWholesaleClick={this.onCancelWholesaleClick}

                    onCPOSelectChange={this.onCPOSelectChange}
                    onOkCPOChange={this.onOkCPOChange}
                    onCancelCPOClick={this.onCancelCPOClick}

                    onUndoOkClick={this.onUndoOkClick}
                    onCancelUndoClick={this.onCancelUndoClick}

                    onAddCommentColumnCallback={this.onAddCommentColumnCallback}

                    onEstCompletionDateColumnCallback={this.onEstCompletionDateColumnCallback}
                    onEstCompletionDateChange={this.onEstCompletionDateChange}

                    roNumberColumnCallback={this.roNumberColumnCallback}
                />
            </div>
        </div>;
    }
}