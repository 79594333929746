import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGQueryComponent } from "../common/dgdgquery/DGDGQueryComponent";

export class DealIssuesDetailQueryComponent extends React.Component {
    static StoreNumberCookie = "DealIssuesDetailStoreNumberCookie";
    static TypeCookie = "DealIssuesDetailTypeCookie";
    static StatusCookie = "DealIssuesDetailStatusCookie";

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("DealIssuesDetailQueryComponent - getDerivedStateFromProps");
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedStore: null,
            selectedType: null,
            selectedStatus: null,
            showSpinner: false
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onTypeChange = this.onTypeChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    typeList = [
        { key: "All", value: "All" },
        { key: "Deal", value: "Deal" },
        { key: "Incentive", value: "Incentive" },
        { key: "DMV Service", value: "DMV Service" },
        { key: "DMV Finance", value: "DMV Finance" },
        { key: "Aftermarket", value: "Aftermarket" },
        { key: "Title", value: "Title" },
        { key: "Other", value: "Other" }
    ];

    statusList = [
        { key: "All", value: "All" },
        { key: "Open,Resolved", value: "Open & Resolved" },
        { key: "Open", value: "Open" },
        { key: "Resolved", value: "Resolved" },
        { key: "Closed", value: "Closed" }
    ];

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.stores && this.state.selectedStore === null) {
                let selectedStore = this.props.stores[0];
                let selectedType = null;
                let selectedStatus = null;
                if (this.props.match.params.StoreNumber) {
                    let storeNumber = Number(this.props.match.params.StoreNumber);
                    if (storeNumber && storeNumber > 0) {
                        let filterStore = this.props.stores.filter(store => storeNumber === store.store_number);
                        if (filterStore.length > 0) {
                            selectedStore = filterStore[0];
                        }
                    }
                }
                else {
                    let cookieStoreNumber = CommonService.getCookie(DealIssuesDetailQueryComponent.StoreNumberCookie);
                    if (cookieStoreNumber) {
                        cookieStoreNumber = parseInt(cookieStoreNumber);
                        let filterStore = this.props.stores.filter(store => store.store_number === cookieStoreNumber);
                        if (filterStore.length === 1) {
                            selectedStore = filterStore[0];
                        }
                    }
                }

                if (this.props.match.params.Type) {
                    let filterType = this.typeList.filter(issue => issue.key === this.props.match.params.Type);
                    if (filterType.length === 1) {
                        selectedType = filterType[0];
                    }
                }
                else {
                    let cookieType = CommonService.getCookie(DealIssuesDetailQueryComponent.TypeCookie);
                    if (cookieType) {
                        let filterType = this.typeList.filter(issue => issue.key === cookieType);
                        if (filterType.length === 1) {
                            selectedType = filterType[0];
                        }
                    }
                }

                if (this.props.match.params.Status) {
                    let filterStatus = this.statusList.filter(issue => issue.key === this.props.match.params.Status);
                    if (filterStatus.length === 1) {
                        selectedStatus = filterStatus[0];
                    }
                }
                else {
                    let cookieStatus = CommonService.getCookie(DealIssuesDetailQueryComponent.StatusCookie);
                    if (cookieStatus) {
                        let filterStatus = this.statusList.filter(issue => issue.key === cookieStatus);
                        if (filterStatus.length === 1) {
                            selectedStatus = filterStatus[0];
                        }
                    }
                }

                this.setState({
                    selectedStore: selectedStore,
                    selectedType: selectedType,
                    selectedStatus: selectedStatus
                }, this.onGetReportClick);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(event, store) {
        try {
            this.setState({ selectedStore: store });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStatusChange(id, status) {
        try {
            this.setState({ selectedStatus: status })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTypeChange(event, type) {
        try {
            this.setState({ selectedType: type })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            if (!this.props.match.params.StoreNumber) {
                CommonService.setCookie(DealIssuesDetailQueryComponent.StoreNumberCookie, this.state.selectedStore.store_number, 30);
                if (this.state.selectedType) {
                    CommonService.setCookie(DealIssuesDetailQueryComponent.TypeCookie, this.state.selectedType.key, 30);
                }
                if (this.state.selectedStatus) {
                    CommonService.setCookie(DealIssuesDetailQueryComponent.StatusCookie, this.state.selectedStatus.key, 30);
                }
            }
            this.setState({ showSpinner: true });
            this.props.onGetReportClick(this.state.selectedStore, this.state.selectedType, this.state.selectedStatus);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGQueryComponent cssName="dgdg-card-gutter">
            <DGDGFormDropdownComponent label="Store #" itemKey="display_name" data={this.props.stores} value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} />
            <DGDGFormDropdownComponent label="Type" itemKey="value" data={this.typeList} value={this.state.selectedType ? this.state.selectedType.value : "Select Type"} onItemClick={this.onTypeChange} />
            <DGDGFormDropdownComponent label="Status" itemKey="value" data={this.statusList} value={this.state.selectedStatus ? this.state.selectedStatus.value : "Select Status"} onItemClick={this.onStatusChange} />
            <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                Get Report<i className="fas fa-redo-alt dgdg-icon" />
            </button>
            <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.props.onAddDealIssuesClick(event)}>
                Add Deal Issues <span className="fas fa-plus dgdg-icon" />
            </button>
            <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
        </DGDGQueryComponent>;
    }
}
