import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { CITDetailComponent } from "./CITDetailComponent";
import { CITSummaryComponent } from "./CITSummaryComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class CITRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stores: null,
            lastUpdatedDate: ""
        };

        this.onLastUpdatedDateChanged = this.onLastUpdatedDateChanged.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("CITRouteComponent - componentDidMount");
            this.getStores();
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("CITRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Contracts-In-Transit";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/CITRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/CIT/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "CITRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CIT/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/CITRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/CIT/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ stores: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CIT/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onLastUpdatedDateChanged(lastUpdatedDate) {
        try {
            this.setState({ lastUpdatedDate: lastUpdatedDate });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-responsive-page dgdg-flex dgdg-flex-column">
            <BrowserRouter>
                <nav className="nav nav-pills dgdg-tab">
                    <Link to="/CIT/Detail" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Detail") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Detail</Link>
                    <Link to="/CIT/Summary" className={"nav-item nav-link mr-auto" + (window.location.pathname.indexOf("/Summary") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Summary</Link>
                    {
                        this.state.lastUpdatedDate
                            ? <div className="mt-auto dgdg-flex-item dgdg-badge-margin">
                                <span className="float-right badge badge-primary dgdg-badge">Last Updated Date: {CommonService.formatDateTimeZone(this.state.lastUpdatedDate)}</span>
                            </div>
                            : null
                    }
                </nav>
                <div className="dgdg-flex-item dgdg-site-content dgdg-cit-content">
                    <Switch>
                        <Route exact path="/CIT/Detail" render={(routeProps) => <CITDetailComponent {...this.props} {...routeProps} stores={this.state.stores} onShowAlert={this.props.onShowAlert} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />
                        <Route exact path="/CIT/Summary" render={(routeProps) => <CITSummaryComponent {...this.props} {...routeProps} />} onShowAlert={this.props.onShowAlert} />
                        <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
                    </Switch>
                </div>
            </BrowserRouter>
        </DGDGPageComponent>;
    }
}
