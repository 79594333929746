import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableHeaderComponent } from "../common/dgdgtable/DGDGTableHeaderComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class CalculateMMRRouteComponent extends React.Component {
    static ZipCookie = "CalculateMMRZipCookie";
    static VinCookie = "CalculateMMRVinCookie";
    static OdometerCookie = "CalculateMMROdometerCookie";
    static GradeCookie = "CalculateMMRGradeCookie";
    static ColorCookie = "CalculateMMRColorCookie";

    constructor(props) {
        super(props);
        this.state = {
            zip: null,
            vin: null,
            odometer: null,
            grade: null,
            isLoadingData: false,
            calculatePriceJson: "",
            calculatePriceApiRequest: "",
            tableData: [],
            tableTitle: "",
            paintTable: false,
            colorList: [
                { key: "Beige", value: "Beige" },
                { key: "Black", value: "Black" },
                { key: "Blue", value: "Blue" },
                { key: "Brown", value: "Brown" },
                { key: "Burgundy", value: "Burgundy" },
                { key: "Gold", value: "Gold" },
                { key: "Gray", value: "Gray" },
                { key: "Green", value: "Green" },
                { key: "Off-white", value: "Off-white" },
                { key: "Orange", value: "Orange" },
                { key: "Pink", value: "Pink" },
                { key: "Purple", value: "Purple" },
                { key: "Red", value: "Red" },
                { key: "Silver", value: "Silver" },
                { key: "Turquoise", value: "Turquoise" },
                { key: "White", value: "White" },
                { key: "Yellow", value: "Yellow" }
            ],
            selectedColor: null,
            showMMRAPIResponse: false
        };

        this.onCalculatePriceClick = this.onCalculatePriceClick.bind(this);
        this.onMMRAPIResponseClick = this.onMMRAPIResponseClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onColorChange = this.onColorChange.bind(this);
        this.onCopyToClipboard = this.onCopyToClipboard.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Calclulate MMR"

    componentDidMount() {
        try {
            console.devLog("CalculateMMRRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/CalculateMMR", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("CalculateMMRRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.state.zip == null || this.state.vin === null || this.state.odometer === null || this.state.grade === null) {
                let zip = this.state.zip;
                if (!zip) {
                    zip = CommonService.getCookie(CalculateMMRRouteComponent.ZipCookie);
                }

                let vin = this.state.vin;
                if (!vin) {
                    vin = CommonService.getCookie(CalculateMMRRouteComponent.VinCookie);
                }

                let odometer = this.state.odometer;
                if (!odometer) {
                    odometer = CommonService.getCookie(CalculateMMRRouteComponent.OdometerCookie);
                }

                let grade = this.state.grade;
                if (!grade) {
                    grade = CommonService.getCookie(CalculateMMRRouteComponent.GradeCookie);
                }

                let selectedColor = null;
                let cookieColor = CommonService.getCookie(CalculateMMRRouteComponent.ColorCookie);
                if (cookieColor) {
                    let filterColor = this.state.colorList.filter(color => color.value === cookieColor);
                    if (filterColor.length === 1) {
                        selectedColor = filterColor[0];
                    }
                }

                this.setState({
                    zip: zip,
                    vin: vin,
                    odometer: odometer,
                    grade: grade,
                    selectedColor: selectedColor
                }, this.onCalculatePriceClick);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Calculate MMR";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/CalculateMMRRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/CalculateMMR/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "CalculateMMRRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CalculateMMR/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onCalculatePriceClick();
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onColorChange(event, color) {
        try {
            this.setState({
                selectedColor: color
            })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCalculatePriceClick() {
        this.getMMRPrice("CalculatePrice");
    }

    onMMRAPIResponseClick(event) {
        this.getMMRPrice("MMRAPIResponse");
    }

    getMMRPrice(buttonName) {
        try {
            CommonService.setCookie(CalculateMMRRouteComponent.ZipCookie, this.state.zip, 30);
            CommonService.setCookie(CalculateMMRRouteComponent.VinCookie, this.state.vin, 30);
            CommonService.setCookie(CalculateMMRRouteComponent.OdometerCookie, this.state.odometer, 30);
            CommonService.setCookie(CalculateMMRRouteComponent.GradeCookie, this.state.grade, 30);
            CommonService.setCookie(CalculateMMRRouteComponent.ColorCookie, this.state.selectedColor.value, 30);
            this.setState({ isLoadingData: true });
            var inputData = {
                zip: this.state.zip,
                vin: this.state.vin,
                odometer: this.state.odometer,
                grade: this.state.grade,
                color: this.state.selectedColor.value
            };

            this.props.applicationInsights.trackTrace({ message: "/CalculateMMRRouteComponent/onCalculatePriceClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/CalculateMMR/GetMMRPrice", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: CommonService.addOrdinalColumn(parsedResponse?.items),
                        tableTitle: this.state.zip + " - " + this.state.vin + " - " + this.state.odometer + " - " + this.state.grade + " - " + this.state.selectedColor?.value,
                        paintTable: true,
                        calculatePriceJson: JSON.stringify(parsedResponse?.items, null, "\t"),
                        calculatePriceApiRequest: JSON.stringify(parsedResponse?.href),
                        showMMRAPIResponse: buttonName === "MMRAPIResponse" ? true : false
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        calculatePriceJson: "",
                        calculatePriceApiRequest: "",
                        showMMRAPIResponse: false
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CalculateMMRRouteComponent/onCalculatePriceClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyToClipboard(event, value) {
        try {
            navigator.clipboard.writeText(value);
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("SellToDGDGCalculateOfferComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("ServiceStoreReportMonthComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                <div className="card dgdg-card-gutter">
                    <div className="card-block">
                        <div className="form-inline">
                            <table style={{ "width": "100%" }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="form-inline">
                                                <div className="form-row">
                                                    <DGDGFormInputComponent id="zip" controlCssName="dgdg-calcuate-mmr-input-width" label="Zip" value={this.state.zip} onChange={this.onTextChange} />
                                                    <DGDGFormInputComponent id="vin" label="VIN" value={this.state.vin} onChange={this.onTextChange} />
                                                    <DGDGFormInputComponent id="odometer" label="Odometer" controlCssName="dgdg-calcuate-mmr-input-width" value={this.state.odometer} onChange={this.onTextChange} />
                                                    <DGDGFormInputComponent id="grade" label="Grade" controlCssName="dgdg-calcuate-mmr-input-width" value={this.state.grade} onChange={this.onTextChange} />
                                                    <DGDGFormDropdownComponent label="Color" itemKey="value" controlCssName="dgdg-calcuate-mmr-input-width" data={this.state.colorList} value={this.state.selectedColor ? this.state.selectedColor.value : "Select Color"} onItemClick={this.onColorChange} onKeyDown={this.onKeyDown} />
                                                    <button className="btn btn-primary dgdg-icon" onClick={(event) => this.onCalculatePriceClick(event)} disabled={this.state.isLoadingData || !this.state.zip || this.state.vin?.length !== 17 || !this.state.odometer || !this.state.grade}>
                                                        Calculate Price<span className="fas fa-redo-alt dgdg-icon" />
                                                    </button>
                                                    <button className="btn btn-primary dgdg-icon" onClick={(event) => this.onMMRAPIResponseClick(event)} disabled={this.state.isLoadingData || !this.state.zip || this.state.vin?.length !== 17 || !this.state.odometer || !this.state.grade}>
                                                        MMR API Response
                                                    </button>
                                                    <DGDGSpinnerComponent showSpinner={this.state.isLoadingData} />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <DGDGModalDialogComponent
                    applicationInsights={this.props.applicationInsights}
                    title={"MMR API Response: " + this.state.zip + "-" + this.state.vin + "-" + this.state.odometer + "-" + this.state.grade + "-" + this.state.selectedColor?.value}
                    showDialog={this.state.showMMRAPIResponse}
                    cssName="dgdg-calcuate-mmr-api-response-dialog"
                    onCancelClick={(event) => { this.setState({ showMMRAPIResponse: false }); }}>
                    <DGDGModalDialogHeaderButtonComponent>
                        <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, this.state.calculatePriceApiRequest)}>
                            Copy Request<span className="far fa-copy dgdg-icon" />
                        </button>
                        <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, this.state.calculatePriceJson)}>
                            Copy Response<span className="far fa-copy dgdg-icon" />
                        </button>
                    </DGDGModalDialogHeaderButtonComponent>
                    <div className="dgdg-report-body">
                        <div className="card">
                            <div className="card-block">
                                <div className="form">
                                    <DGDGFormTextAreaComponent value={this.state.calculatePriceApiRequest} rows="1" readOnly controlSpan="dgdg-calcuate-mmr-api-response-textbox-width" />
                                    <br />
                                    <DGDGFormTextAreaComponent value={this.state.calculatePriceJson} rows="38" readOnly controlSpan="dgdg-calcuate-mmr-api-response-textbox-width" />
                                </div>
                            </div>
                        </div>
                    </div>
                </DGDGModalDialogComponent>
                <div className="dgdg-site-scrollable-content">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable}
                        tablePageSize={CommonService.tablePageSize} copyTableConfig={{ "columns": [0, 7, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], onCopyTableCallBack: this.onCopyTableCallBack }}

                    > <DGDGTableHeaderComponent position="PreHeader">
                            <tr className="dgdg-table-v3-header">
                                <td />
                                <td colSpan="8" />
                                <td colSpan="3" className="text-center font-weight-bold">Retail Adjusted Pricing</td>
                            </tr>
                        </DGDGTableHeaderComponent>
                        <DGDGTableRowComponent hasHeader>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="Year" dataColumn="description.year" sortColumn="description.year" filterColumn="description.year" />
                            <DGDGTableColumnComponent headerText="Make" dataColumn="description.make" sortColumn="description.make" filterColumn="description.make" />
                            <DGDGTableColumnComponent headerText="Model" dataColumn="description.model" sortColumn="description.model" filterColumn="description.model" />
                            <DGDGTableColumnComponent headerText="Trim" dataColumn="description.trim" sortColumn="description.trim" filterColumn="description.trim" />
                            <DGDGTableColumnComponent headerText="Subseries" dataColumn="description.subSeries" sortColumn="description.subSeries" filterColumn="description.subSeries" />
                            <DGDGTableColumnComponent headerText="Transmission" dataColumn="description.transmission" sortColumn="description.transmission" filterColumn="description.transmission" />
                            <DGDGTableColumnComponent headerText="Average Odometer" dataColumn="averageOdometer" sortColumn="averageOdometer" filterColumn="averageOdometer" dataType="formatted_number" />
                            <DGDGTableColumnComponent headerText="Average Grade" dataColumn="averageGrade" sortColumn="averageGrade" filterColumn="averageGrade" />
                            <DGDGTableColumnComponent headerText="Above" dataColumn="adjustedPricing.retail.above" sortColumn="adjustedPricing.retail.above" filterColumn="adjustedPricing.retail.above" dataType="money" />
                            <DGDGTableColumnComponent headerText="Average" dataColumn="adjustedPricing.retail.average" sortColumn="adjustedPricing.retail.average" filterColumn="adjustedPricing.retail.average" dataType="money" />
                            <DGDGTableColumnComponent headerText="Below" dataColumn="adjustedPricing.retail.below" sortColumn="adjustedPricing.retail.below" filterColumn="adjustedPricing.retail.below" dataType="money" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </DGDGPageComponent>
    }
}